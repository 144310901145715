<template>
  <div class="training-addVideo">
      <div class="title">请填写视频相关信息</div>
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="视频名称：">
        <el-input v-model="form.title" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="讲师：">
        <el-select v-model="form.lecturer_id" placeholder="请选择">
          <el-option
            v-for="item in lecturerOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="展厅：">
        <el-select v-model="form.room_id" placeholder="请选择">
          <el-option
            v-for="item in roomOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="会议时间：">
        <el-select v-model="form.meeting_time" placeholder="请选择">
          <el-option
            v-for="item in meetingTimeList"
            :key="item.meeting_date"
            :label="item.meeting_date"
            :value="item.meeting_date"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="视频：">
        <el-upload
          class="avatar-uploader"
          list-type="picture-card"
          :limit="1"
          :action="uploadVideoUrl"
          :on-success="handleVideoSuccess"
          :before-upload="beforeUploadVideo"
          :show-file-list="false"
          :data="qiniuVideoData"
        >
          <video
            v-if="showVideoPath != '' && !videoFlag"
            :src="showVideoPath"
            class="avatar video-avatar"
            controls="controls"
          >
            您的浏览器不支持视频播放
          </video>
          <i
            v-else-if="showVideoPath == '' && !videoFlag"
            class="el-icon-plus"
          ></i>
          <el-progress
            v-if="videoFlag == true"
            type="circle"
            :percentage="videoUploadPercent"
            style="margin-top: 7px"
          ></el-progress>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSaveAdd">立刻添加</el-button>
      <el-button @click="cencel" class="cencel">取 消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {},
      lecturerOptions: [],
      roomOptions: [],
      videoFlag: false,
      videoUploadPercent: '',
      isShowUploadVideo: false,
      showVideoUrlL: '',
      showVideoPath: '',
      uploadVideoUrl: 'https://up-z2.qiniup.com',
      qiniuVideoData: {
        key: '',
        token: ''
      },
      qiniuVideoDomain: null,
      meetingTimeList: []
    }
  },
  methods: {
      cencel() {
          this.$router.push('./training-video')
      },
    async handleAdd () {
    //   const { code, result } = await fetchVideoCreate()
    //   if (code === 1) {
    //     this.lecturerOptions = result.lecturer
    //     this.roomOptions = result.list
    //     this.meetingTimeList = result.date
    //   }
    },
    async handleSaveAdd () {
    //   const { code } = await addVideo(this.form)
    //   if (code === 1) {
    //     this.$message.success('添加成功')
    //     this.dialogVisible = false
    //     this.getList()
    //   }
    },
    async handleDelete (id) {
    //   const { code } = await deleteVideo({ id: id })
    //   if (code === 1) {
    //     this.$message.success('删除成功')
    //     this.getList()
    //   }
    },
    async beforeUploadVideo (file) {
    //   const isMP4 = file.type === 'video/mp4'
    //   if (!isMP4) {
    //     this.$message.error('上传视频只能是MP4格式!')
    //   }
    //   if (isMP4) {
    //     const { code, result } = await uploadImage()
    //     if (code === 1) {
    //       this.qiniuVideoData.key = result.oss_file_name
    //       this.qiniuVideoData.token = result.token
    //       this.qiniuVideoDomain = result.domain
    //       this.isShowUploadVideo = false
    //     }
    //   }
    },
    handleVideoSuccess (res) {
    //   this.isShowUploadVideo = true
    //   this.videoFlag = false
    //   this.videoUploadPercent = 0
    //   this.showVideoPath = this.qiniuVideoDomain + `${res.key}`
    //   this.form.video_url = res.key
    //   if (this.showVideoPath) {
    //     this.hasImgAndVideo = false
    //   }
    }
  }
}
</script>

<style lang="less" scoped>
.training-addVideo {
    padding: 0 30px;
    .title{
        font-size: 16px;
        line-height: 60px;
    }
  .wrap {
    padding: 20px;
    color: #666;
  }
  .mt20 {
    margin-top: 20px;
    
  }
  .ml140 {
    margin-left: 140px;
  }
  .video-avatar {
    height: 150px;
  }
  ::v-deep .el-form-item__label{
      text-align: left;
        font-weight: 700 !important;
  }
  .dialog-footer{
      padding-left: 70px;
  }
  .cencel{
      margin-left: 30px;
  }
}
</style>
